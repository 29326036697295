import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import {
  Usuario,
  AuthService,
  CustomLocalStorageService,
  GenericModalComponent,
} from "dtp-angular-library";
import { NotifierService } from "angular-notifier";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { RecaptchaComponent } from "ng-recaptcha";
import { MfaTokenService } from "src/app/uboi/base/services/jbs-mfa-token.service";
import { JbsAdminMfaToken } from "src/app/uboi/base/models/jbs-admin-mfa-token";
import { JbsAdminMfaTokenModel } from "src/app/uboi/base/models/jbs-admin-mfa-token-model";
import { map } from "rxjs/operators";

@Component({
  selector: "dtp-login",
  templateUrl: "./two-factor.component.html",
  styleUrls: ["./two-factor.component.css"],
})
export class TwoFactorComponent implements OnInit {
  token: string = null;
  usuario: Usuario;
  mfaToken: JbsAdminMfaTokenModel;
  expirado = false;
  urlRedirect = "inicio";

  siteKey: string;

  @Output() logado = new EventEmitter<boolean>();

  @ViewChild("modalPasswordReset", { static: true })
  modalPasswordReset: GenericModalComponent;
  @ViewChild("reCaptcha", { static: false }) captcha: RecaptchaComponent;

  formPasswordReset: FormGroup;
  mfaHashcode: string;

  constructor(
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private notifier: NotifierService,
    private localStorage: CustomLocalStorageService,
    private route: ActivatedRoute,
    private mfaLogin: MfaTokenService
  ) {
    this.usuario = new Usuario();
    this.mfaToken = new JbsAdminMfaTokenModel();
    this.mfaToken.token = "";
    this.notifier = notifier;

    this.siteKey = (<any>window).config.recaptchaSiteKey;
  }

  ngOnInit() {
    console.log("started");

    if (this.router.getCurrentNavigation().extras.state) {
      if (this.router.getCurrentNavigation().extras.state.expired) {
        this.expirado = true;
      }

      if (this.router.getCurrentNavigation().extras.state.urlRedirect) {
        this.urlRedirect =
          this.router.getCurrentNavigation().extras.state.urlRedirect;
      }
    }
  }

  ValidarMfaLogin() {
    if (this.mfaToken.token.trim() === "") {
      this.notifier.notify("error", "Por favor, preencha o Token.");
    } else {
      this.route.queryParams.subscribe((params) => {
        this.mfaHashcode = params.hashcode;
      });

      const envioToken = new JbsAdminMfaToken();
      envioToken.hashcode = this.mfaHashcode;
      envioToken.token = this.mfaToken.token;
      this.mfaLogin
        .MfaLogin(envioToken)
        .pipe(map((response: any) => response))
        .subscribe(
          (ret: Object) => {
            this.usuario.Login = localStorage.getItem("userName");
            this.usuario.Senha = localStorage.getItem("password");

            this.auth.Autenticar(this.usuario).subscribe((ret) => {
              this.router.navigateByUrl(this.urlRedirect);
              this.logado.emit(true);
            });
            this.mfaLogin.Autenticado(true);
          },
          (erro) => {
            this.notifier.notify(
              "error",
              typeof erro.error == "string"
                ? erro.error
                : "Ocorreu um erro, mais informações no console."
            );
            if (typeof erro.error !== "string") {
              console.error("ERRO => ", erro);
            }
          }
        );
    }
  }

  ResendMfaToken() {
    this.route.queryParams.subscribe((params) => {
      this.mfaHashcode = params.hashcode;
    });

    this.mfaLogin
      .ResendMfaLogin(this.mfaHashcode)
      .pipe(map((response: any) => response.hashcode))
      .subscribe(
        (hashcode: string) => {
          this.router.navigate(["/two-factor-auth"], {
            queryParams: { hashcode: hashcode },
          });
        },
        (erro) => {
          this.notifier.notify(
            "error",
            typeof erro.error == "string"
              ? erro.error
              : "Ocorreu um erro, mais informações no console."
          );
          if (typeof erro.error !== "string") {
            console.error("ERRO => ", erro);
          }
        }
      );
  }
}
