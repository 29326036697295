import { RouterModule } from '@angular/router';
import { DtpAngularLibraryModule } from 'dtp-angular-library';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DebounceClickDirective } from './debounce-click/debounce-click.directive';

@NgModule({
  declarations: [DebounceClickDirective],
  exports: [DebounceClickDirective],
  imports: [CommonModule, DtpAngularLibraryModule, RouterModule]
})
export class DirectivesModule { }
