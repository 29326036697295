import { RouterModule } from '@angular/router';
import { DtpAngularLibraryModule } from 'dtp-angular-library';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JbsNavsideComponent } from './jbs-navside/jbs-navside.component';

@NgModule({
  declarations: [JbsNavsideComponent],
  exports: [JbsNavsideComponent],
  imports: [CommonModule, DtpAngularLibraryModule, RouterModule]
})
export class ComponentesModule { }
