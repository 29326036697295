import { ModelBase } from 'dtp-angular-library';

export class JbsParametro extends ModelBase {
    Nome: string;
    Texto_aceite_contrato: string;

    protected addKeys() {
        this.keys.push("Nome");
    }

    toModel(jsonData: any): JbsParametro {
        return Object.assign(new JbsParametro(), jsonData, { keys: this.keys });
    }

    modelConfiguration() {
        this.modelDisplay = 'Parametro';
        this.alphanumericKey = true;
    }
}