import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DtpAngularLibraryModule } from 'dtp-angular-library';
import { AgmDirectionModule } from 'agm-direction';
import { AgmCoreModule } from '@agm/core';
import { NgxMaskModule } from 'ngx-mask';
import { ComponentesModule } from './componentes/componentes.module';

@NgModule({
  declarations: [],
  exports: [
    CommonModule,
    DtpAngularLibraryModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxMaskModule,
    AgmCoreModule,
    AgmDirectionModule
  ],
  imports: [
    CommonModule,
    DtpAngularLibraryModule,
    ComponentesModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxMaskModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAQrbdTyHu4T6inTEvD5E4XVIUliMuqCa4'
    }),
    AgmDirectionModule,
  ]
})
export class UboiBaseModule { }
