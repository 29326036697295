import { JbsParametro } from './../../../base/models/jbs-parametro';
import { JbsParametroService } from './../../../base/services/jbs-parametro.service';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'dtp-termos-contrato-cadastro',
  templateUrl: './termos-contrato-cadastro.component.html',
  styleUrls: ['./termos-contrato-cadastro.component.css']
})
export class TermosContratoCadastroComponent implements OnInit {
  linkContrato: string;
  linkTratado: SafeResourceUrl;

  constructor(private service: JbsParametroService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.ObterContrato();
  }

  ObterContrato() {
    this.service.Obter().subscribe(itens => {
      
      if(itens.length > 0) {
        this.linkContrato = itens[0].Texto_aceite_contrato;
        this.pdfUrl();
      }

    });
  }

  SalvarAnexo(link: any) {
    console.log(link);

    let model = new JbsParametro();

    model.Nome = "App uBoi";
    model.Texto_aceite_contrato = link[0].urlimg;

    this.service.Salvar(model, true).subscribe(item => {
      this.linkContrato = link[0].urlimg;
      this.pdfUrl();
    });
  };

  pdfUrl() {
    this.linkTratado = this.sanitizer.bypassSecurityTrustResourceUrl(this.linkContrato);
  }

}
