import { HttpHeaders } from "@angular/common/http";
import { JbsAdminMfaToken } from "./../models/jbs-admin-mfa-token";
import { Injectable, Injector } from "@angular/core";
import {
  RelatedServiceBase,
  ServiceCrudBase,
  Usuario,
} from "dtp-angular-library";

@Injectable({
  providedIn: "root",
})
export class MfaTokenService extends RelatedServiceBase<JbsAdminMfaToken> {
  constructor(protected injector: Injector) {
    super("JbsAdminMfaToken", injector, JbsAdminMfaToken);
  }

  MfaLogin(model: JbsAdminMfaToken) {
    const url = this.config.Configuracoes().urlApi + "Token/mfa-login";

    const headers = new HttpHeaders();

    const formData = new FormData();
    formData.append("hashcode", model.hashcode);
    formData.append("token", model.token);

    return this.http.post(url, formData);
  }

  ResendMfaLogin(hashcode: string) {
    const url = `${
      this.config.Configuracoes().urlApi
    }Token/resend-mfa-token/${hashcode}`;
    console.log(url);

    return this.http.post(url, null);
  }

  ObterToken(usuario: Usuario) {
    const url = this.config.Configuracoes().urlApi + "Token/mfa-token";

    const headers = new HttpHeaders();

    const formData = new FormData();
    formData.append("userName", usuario.Login);
    formData.append("password", usuario.Senha);

    return this.http.post(url, formData);
  }

  Autenticado(isTrue: Boolean) {
    return isTrue ? isTrue : false;
  }
}
