import { Component, OnInit, Input } from '@angular/core';
import { Menu, DropDownMenu } from 'dtp-angular-library';

@Component({
  selector: 'jbs-navside',
  templateUrl: './jbs-navside.component.html',
  styleUrls: ['./jbs-navside.component.css']
})
export class JbsNavsideComponent implements OnInit {

  @Input() menus: DropDownMenu;
  @Input() routerFavoriteLink: string;

  expanded: boolean = false;

  constructor() {
  }

  onEvent(event) {
    event.stopPropagation();
  }

  onClick() {
    this.expanded = false;

  }

  ngOnInit() {
  }

}
