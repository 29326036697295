import { ModelBase } from "dtp-angular-library";

export class JbsAdminMfaToken extends ModelBase {
  hashcode: string;
  token: string;

  protected addKeys() {
    this.keys.push("token");
  }
  toModel(jsonData: any) {
    return Object.assign(new JbsAdminMfaToken(), jsonData, { keys: this.keys });
  }

  modelConfiguration() {
    this.modelDisplay = "Analista";
  }
}
