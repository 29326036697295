import { TermosContratoCadastroComponent } from './paginas/termos-contrato/termos-contrato-cadastro/termos-contrato-cadastro.component';
import { PaginaInicioComponent } from './paginas/inicio/pagina-inicio.component';
import { NgModule } from '@angular/core';
import { UboiBaseModule } from './uboi-base.module';
import { UBoiRoutingModule } from './uboi-routing.module';
import { InicioComponent } from './core/inicio/inicio.component';
import { AutorizacaoModule } from './core/autorizacao/autorizacao.module';
import { DirectivesModule } from './directives/DirectivesModule';

@NgModule({
  declarations: [
    InicioComponent,
    PaginaInicioComponent,
    TermosContratoCadastroComponent
  ],
  exports: [InicioComponent, PaginaInicioComponent, UboiBaseModule, DirectivesModule],
  imports: [
    UboiBaseModule,
    AutorizacaoModule,
    UBoiRoutingModule,
    DirectivesModule
  ]
})
export class UboiModule { }
