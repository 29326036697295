import { Component, OnInit } from "@angular/core";
import {
  DropDownMenu,
  MenuPermissoes,
  AuthService,
  CustomLocalStorageService,
  DropDownMenuService,
  MenufavoritosService,
  Menu,
} from "dtp-angular-library";
import { Observable } from "rxjs";
import { MfaTokenService } from "src/app/uboi/base/services/jbs-mfa-token.service";

@Component({
  selector: "dtp-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "datapar-uboi-webapp";
  public menus: DropDownMenu;
  permissoes: MenuPermissoes = new MenuPermissoes();

  constructor(
    public authService: AuthService,
    private localStorage: CustomLocalStorageService,
    private serviceMenu: DropDownMenuService,
    public mfaTokenService: MfaTokenService
  ) {}

  atualizarMenus() {
    this.serviceMenu.Obter().subscribe((i) => {
      this.menus = i;
      const menusarray = i as unknown as DropDownMenu[];

      const permissoes = menusarray.map((menu) => {
        return menu.lSubcategorias.map((item) => {
          return {
            codfrm: item.CodFrm,
            rota: item.Url,
            display: item.Display,
            icone: item.Icone,
            custom: (item as any).Custom,
            rotina: (item as any).Rotina,
            permissoes: item.Permissoes,
          };
        });
      });

      const merged = [].concat.apply([], permissoes).flat();
      this.localStorage.setItem("permissoes", JSON.stringify(merged));
    });
  }

  ngOnInit(): void {
    this.permissoes.PermiteAlterar = false;
    this.permissoes.PermiteExcluirFilhos = true;
    this.permissoes.PermiteExcluir = true;

    this.atualizarMenus();
  }

  rotaAlterada(componente) {
    if (componente.logado) {
      componente.logado.subscribe((atualiza: boolean) => {
        this.atualizarMenus();
      });
    }
  }
}
