import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DtpAngularLibraryModule } from 'dtp-angular-library';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { ComponentesModule } from '../../componentes/componentes.module';
import { RecaptchaModule } from 'ng-recaptcha';
import { TwoFactorComponent } from './two-factor/two-factor.component';

@NgModule({
  declarations: [LoginComponent, TwoFactorComponent],
  exports: [LoginComponent, TwoFactorComponent],
  imports: [
    CommonModule,
    DtpAngularLibraryModule,
    ComponentesModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
  ],
})
export class AutorizacaoModule {}
