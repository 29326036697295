import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import {
  Usuario,
  AuthService,
  CustomLocalStorageService,
  GenericModalComponent,
} from "dtp-angular-library";
import { NotifierService } from "angular-notifier";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { RecaptchaComponent } from "ng-recaptcha";
import { map } from "rxjs/operators";
import { MfaTokenService } from "src/app/uboi/base/services/jbs-mfa-token.service";

@Component({
  selector: "dtp-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  token: string = null;
  usuario: Usuario;
  expirado = false;

  siteKey: string;

  @ViewChild("modalPasswordReset", { static: true })
  modalPasswordReset: GenericModalComponent;
  @ViewChild("reCaptcha", { static: false }) captcha: RecaptchaComponent;

  formPasswordReset: FormGroup;

  constructor(
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private notifier: NotifierService,
    private localStorage: CustomLocalStorageService,
    private mfaLogin: MfaTokenService
  ) {
    this.usuario = new Usuario();
    this.notifier = notifier;

    this.siteKey = (<any>window).config.recaptchaSiteKey;

    if (this.router.getCurrentNavigation().extras.state) {
      if (this.router.getCurrentNavigation().extras.state.expired) {
        this.expirado = true;
      }
    }
  }

  ngOnInit() {
    if (this.localStorage.existeChave("auth")) {
      this.router.navigate(["inicio"]);
    }

    this.formPasswordReset = this.formBuilder.group(
      {
        password: ["", [Validators.required, Validators.minLength(8)]],
        password_repeat: ["", [Validators.required]],
      },
      { validator: this.checkPasswords }
    );

    console.log("started");
  }

  ValidarLogin() {
    // if (this.captcha) {
    // this.captcha.execute();
    // } else {
    this.Login(null);
    // }
  }

  Login(event) {
    this.usuario.hashCaptcha = event;

    this.localStorage.setItem("userName", this.usuario.Login);
    this.localStorage.setItem("password", this.usuario.Senha);

    this.mfaLogin
      .ObterToken(this.usuario)
      .pipe(map((response: any) => response.hashcode))
      .subscribe(
        (hashcode: string) => {
          this.router.navigate(["/two-factor-auth"], {
            queryParams: { hashcode: hashcode },
          });
        },
        (erro) => {
          if (erro.error.passwordReset) {
            this.token = erro.error.access_token;
            this.OpenPasswordReset();
          } else {
            this.usuario.hashCaptcha = null;
            if (this.captcha) {
              this.captcha.reset();
            }
            this.notifier.notify(
              "error",
              typeof erro.error == "string"
                ? erro.error
                : "Ocorreu um erro, mais informações no console."
            );
            if (typeof erro.error !== "string") {
              console.error("ERRO => ", erro);
            }
          }
        }
      );

    return false;
  }

  checkPasswords(group: FormGroup) {
    const pass = group.get("password").value;
    const confirmPass = group.get("password_repeat").value;

    if (pass === confirmPass) {
      if (confirmPass === "") {
        group.get("password_repeat").setErrors({ required: true });
      } else {
        group.get("password_repeat").setErrors(null);
      }
    } else {
      group
        .get("password_repeat")
        .setErrors({ required: confirmPass === "", passwordConfirm: true });
    }
  }

  OpenPasswordReset() {
    this.modalPasswordReset.abrir();
  }

  SavePasswordReset() {
    this.usuario.Senha = this.formPasswordReset.get("password").value;
    this.usuario.ConfirmaSenha =
      this.formPasswordReset.get("password_repeat").value;

    this.auth.ResetarSenha(this.usuario, this.token).subscribe(
      (data) => {
        if (data === 1) {
          this.modalPasswordReset.fechar();
          this.notifier.notify(
            "success",
            "Senha redefinida com sucesso! Faça o login para acessar."
          );
        }
      },
      (err) => {
        this.notifier.notify(
          "error",
          typeof err.error == "string"
            ? err.error
            : "Ocorreu um erro, mais informações no console."
        );
        if (typeof err.error !== "string") {
          console.error("ERRO => ", err);
        }
      }
    );
  }
}
