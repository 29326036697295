import { Injectable, Injector } from '@angular/core';
import { RelatedServiceBase } from 'dtp-angular-library';
import { JbsParametro } from '../models/jbs-parametro';

@Injectable({
    providedIn: 'root'
})
export class JbsParametroService extends RelatedServiceBase<JbsParametro> {
    constructor(protected injector: Injector) {
        super('JbsParametro', injector, JbsParametro);
    }
}
