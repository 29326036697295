import { TermosContratoCadastroComponent } from './paginas/termos-contrato/termos-contrato-cadastro/termos-contrato-cadastro.component';
import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from './core/inicio/inicio.component';
import { AuthGuardianService } from 'dtp-angular-library';
import { LoginComponent } from './core/autorizacao/login/login.component';
import { NgModule } from '@angular/core';
import { TwoFactorComponent } from './core/autorizacao/two-factor/two-factor.component';

const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
    data: { titulo: "Login" },
  },
  {
    path: "two-factor-auth",
    component: TwoFactorComponent,
    data: { titulo: "Login Dois Fatores" },
  },
  {
    path: "inicio",
    component: InicioComponent,
    canActivate: [AuthGuardianService],
    data: { titulo: "Inicio" },
  },
  {
    path: "",
    component: InicioComponent,
    canActivate: [AuthGuardianService],
    data: { titulo: "Inicio" },
  },
  {
    path: "termos-de-uso",
    component: TermosContratoCadastroComponent,
    canActivate: [AuthGuardianService],
    data: { titulo: "Termos de Uso" },
  },
  {
    path: "cadastro",
    loadChildren: () =>
      import("./paginas/cadastro/cadastro.module").then(
        (m) => m.CadastroModule
      ),
    data: { titulo: "Cadastro" },
  },
  {
    path: "checklist",
    loadChildren: () =>
      import("./paginas/checklist/checklist.module").then(
        (m) => m.ChecklistModule
      ),
    data: { titulo: "Checklist" },
  },
  {
    path: "envio-para-transportadora",
    loadChildren: () =>
      import(
        "./paginas/envio-para-transportadora/envio-para-transportadora.module"
      ).then((m) => m.SendOrderModule),
    data: { titulo: "Envio para a transportadora" },
  },
  {
    path: "grupo-contato",
    loadChildren: () =>
      import("./paginas/grupo-contato/grupo-contato.module").then(
        (m) => m.GrupoContatoModule
      ),
    data: { titulo: "Grupo Contato" },
  },
  {
    //GAMBIARRA
    path: "pedido",
    loadChildren: () =>
      import("./paginas/viagem/viagem.module").then((m) => m.ViagemModule),
    data: { titulo: "Pedido" },
  },
  {
    path: "tracking",
    loadChildren: () =>
      import("./paginas/viagem/viagem.module").then((m) => m.ViagemModule),
    data: { titulo: "tracking" },
  },
  {
    path: "perfil",
    loadChildren: () =>
      import("./paginas/perfil/perfil.module").then((m) => m.PerfilModule),
    data: { titulo: "Perfil" },
  },
  {
    path: "usuario",
    loadChildren: () =>
      import("./paginas/usuario/usuario.module").then((m) => m.UsuarioModule),
    data: { titulo: "Usuário" },
  },
  {
    path: "dados-tagueamento",
    loadChildren: () =>
      import("./paginas/tagueamento-xls/tagueamento-xls.module").then(
        (m) => m.TagueamentoXlsModule
      ),
    data: { titulo: "Dados Tagueamento" },
  },
  {
    path: "parceria",
    loadChildren: () =>
      import("./paginas/parceria/parceria.module").then(
        (m) => m.ParceriaModule
      ),
    data: { titulo: "Parceria" },
  },
  {
    path: "transportadora",
    loadChildren: () =>
      import("./paginas/transportadora/transportadora.module").then(
        (m) => m.TransportadoraModule
      ),
    data: { titulo: "Transportadora" },
  },
  {
    path: "escalador",
    loadChildren: () =>
      import("./paginas/escalador/escalador.module").then(
        (m) => m.EscaladorModule
      ),
    data: { titulo: "Escalador" },
  },
];

@NgModule({
    declarations: [],
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UBoiRoutingModule { }
